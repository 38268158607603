import React from 'react'
import Defend from '../../../assets/svg/eos-icons_admin-outlined.svg'
import server from '../../../assets/svg/Server.svg'
import customcs from '../../../assets/svg/mdi_customer-service.svg'
import chat from '../../../assets/svg/Chat.svg'
import hand from '../../../assets/svg/services/hand.svg'
import duitku from '../../../assets/svg/services/duitku.svg'
import erpNext from '../../../assets/svg/services/erpnext.svg'
import pvg from '../../../assets/svg/services/pvg.svg'
import vultr from '../../../assets/svg/services/vultr.svg'
import locked from '../../../assets/svg/services/locked.svg'
import appstore from '../../../assets/svg/services/appstore.svg'
import googleplay from '../../../assets/svg/services/googleplay.svg'
import website from '../../../assets/svg/services/website.svg'
import iso from '../../../assets/svg/services/iso.svg'
import owasp from '../../../assets/svg/services/wasp.svg'



const DukunganElkopra = () => {
  return (
    <>
    <div className='bg-[#000F23] pt-10 pb-10'>
  <div className='text-center'>
    <h1 className='font-montserrat text-2xl text-white font-semibold'>
      Flexible dan Customize! Solusi Tepat <br /> Optimalkan Alur Bisnis Koperasi Anda
    </h1>
  </div>
  <div className='container mx-auto pb-5 mt-8'>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4">
      <button className="bg-transparent text-blue-900 px-4 py-2 border border-blue-900 rounded-lg">
        <div className='text-center'>
          <img src={hand} alt='hand' className='mx-auto object-contain  h-auto' />
        </div>
        <h1 className='font-montserrat text-lg text-white text-center font-semibold'>
          Semua dalam satu genggaman.
        </h1>
        <h1 className='font-montserrat text-sm text-white text-center font-thin'>
          Raih efisiensi maksimal dengan kemudahan integrasi antar service menjadi Satu Platform
        </h1>
        <div className='flex justify-center mt-5 space-x-5'>
          <img src={duitku} alt='duitku' className='object-contain w-1/5 h-auto' />
          <img src={erpNext} alt='erpNext' className='object-contain w-1/5 h-auto mt-3' />
          <img src={pvg} alt='pvg' className='object-contain w-1/5 h-auto' />
          <img src={vultr} alt='vultr' className='object-contain w-1/5 h-auto' />
        </div>
      </button>
      <button className="bg-transparent text-blue-900 px-4 py-2 border border-blue-900 rounded-lg">
        <div className='text-center'>
          <img src={locked} alt='locked' className='mx-auto object-contain h-auto' />
        </div>
        <h1 className='font-montserrat text-lg text-white text-center font-semibold'>
          Keamanan maksimal, ketenangan optimal. Data Anda aman bersama kami.
        </h1>
        <h1 className='font-montserrat text-sm text-white text-center font-thin'>
          Raih efisiensi maksimal dengan kemudahan integrasi antar service menjadi Satu Platform
        </h1>
        <div className='flex justify-between mt-5 px-5'>
          <div>
            <h1 className='font-montserrat text-sm text-white font-thin'>
              . Micro Service Platform
            </h1>
            <h1 className='font-montserrat text-sm text-white font-thin'>
              . Sensitive Information Encrypted
            </h1>
          </div>
          <div>
            <h1 className='font-montserrat text-sm text-white font-thin'>
              . Technical Support
            </h1>
          </div>
        </div>
        <div className='flex justify-center mt-5 space-x-5'>
          <img src={iso} alt='iso' className='object-contain h-auto' />
          <img src={owasp} alt='owasp' className='object-contain  h-auto' />
        </div>
      </button>
    </div>
  </div>
  <div className='text-center mt-10'>
    <h1 className='font-montserrat text-2xl text-white font-semibold'>
      Available on:
    </h1>
  </div>
  <div className='flex justify-center mt-10 space-x-5'>
    <img src={appstore} alt='appstore' className='object-contain  h-auto' />
    <img src={googleplay} alt='googleplay' className='object-contain  h-auto' />
    <img src={website} alt='website' className='object-contain  h-auto' />
  </div>
</div>
<div className='text-center mt-10 mb-10'>
  <h1 className='font-montserrat text-2xl font-semibold'>
    Dukungan Elkopra
  </h1>
</div>
<div className='container mx-auto mb-5'>
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
    <div className="p-4 bg-white rounded-lg">
      <div className='text-center'>
        <img src={Defend} alt='' className='mx-auto' />
      </div>
      <h1 className='font-montserrat text-lg text-gray-900 text-center mt-5 font-semibold'>
        Maintenance Aplikasi
      </h1>
    </div>
    <div className="p-4 bg-white rounded-lg">
      <div className='text-center'>
        <img src={server} alt='' className='mx-auto' />
      </div>
      <h1 className='font-montserrat text-lg text-gray-900 text-center mt-5 font-semibold'>
        Management Server
      </h1>
    </div>
    <div className="p-4 bg-white rounded-lg">
      <div className='text-center'>
        <img src={customcs} alt='' className='mx-auto' />
      </div>
      <h1 className='font-montserrat text-lg text-gray-900 text-center mt-5 font-semibold'>
        CS Teknis (08.00 - 17.00) at working hours
      </h1>
    </div>
    <div className="p-4 bg-white rounded-lg">
      <div className='text-center'>
        <img src={chat} alt='' className='mx-auto' />
      </div>
      <h1 className='font-montserrat text-lg text-gray-900 text-center mt-5 font-semibold'>
        Informasi Penagihan langganan aplikasi
      </h1>
    </div>
  </div>
</div>
<div className='mt-20 text-center'>
  <h1 className='font-montserrat text-2xl font-semibold'>
    Pertanyaan yang Sering Ditanyakan
  </h1>
</div>
    </>
  )
}

export default DukunganElkopra
