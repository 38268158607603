import React from 'react'
import HeaderHome from '../widget/home/HeaderHome'
import WhyElkopra from '../widget/home/WhyElkopra'
import WorkProcess from '../widget/home/WorkProcess'
import NewsSect from '../widget/home/NewsSect'
import ContactPers from '../widget/home/ContactPers'
import FAQ from '../widget/ourservices/FAQ'
import JaringanMitra from '../widget/home/JaringanMitras'
import Stat from '../widget/home/Stats'
import Testimonis from '../widget/home/Testimoni.js'
import Partisipasis from '../widget/home/Partisipasi.js'

const LandingPage = () => {
  return (
    <>
    <HeaderHome/>
    <WhyElkopra/>
    <WorkProcess/>
    <NewsSect/>
    <ContactPers/>
    <JaringanMitra/>
    <Stat/>
    <Testimonis/>
    <Partisipasis/>
    <FAQ/>
    </>
  )
}

export default LandingPage
