import React from 'react';
import HubungiAdmin from '../../../assets/svg/wp/hubungiadmin.svg';
import KunjunganDemo from '../../../assets/svg/wp/kunjungandemo.svg';
import TTDkerjasama from '../../../assets/svg/wp/ttdkerjasama.svg';
import MasaPercobaan from '../../../assets/svg/wp/masapercobaan.svg';
import PengembanganP from '../../../assets/svg/wp/pengembanganp.svg';
import Done from '../../../assets/svg/wp/done.svg';
import { ReactComponent as ArrowL } from '../../../assets/svg/wp/arrowleft.svg';
import { ReactComponent as ArrowR } from '../../../assets/svg/wp/arrowright.svg';
import { ReactComponent as ArrowRev } from '../../../assets/svg/wp/arrowrev.svg';

const WorkProcess = () => {
  return (
    <div className="text-center pt-20">
      <h1 className="font-montserrat text-2xl mt-10">Work Process</h1>
      <IconSet />
    </div>
  );
};

const IconSet = () => {
  return (
    <div className="flex justify-evenly max-w-screen-lg mx-auto px-4 sm:px-2 lg:px-2">
      {/* Desktop */}
      <div className="hidden sm:block">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 lg:grid-cols-5 gap-4 md:gap-6 lg:gap-8">
          <IconItem image={HubungiAdmin} label="Hubungi Admin" label2="5-10 Menit" />
            <div className="sm:flex items-center justify-center mx-auto"><ArrowR /></div>
          <IconItem image={KunjunganDemo} label="Kunjungan Demo" label2="1 Bulan" />
            <div className="sm:flex items-center justify-center mx-auto"><ArrowR /></div>
          <IconItem image={TTDkerjasama} label="Tanda Tangan Kerja Sama" label2="1 Bulan" />
          <IconItem image={Done} label="Done" />
            <div className="sm:flex items-center justify-center mx-auto"><ArrowL /></div>
          <IconItem image={MasaPercobaan} label="Masa Percobaan" label2="1 Bulan" />
            <div className="sm:flex items-center justify-center mx-auto"><ArrowL /></div>
          <IconItem image={PengembanganP} label="Pengembangan Program" label2="1 Bulan" />
          <ArrowRev className="absolute sm:block" style={{ right: '1', marginTop: '200px', marginRight: '100px' }} />
        </div>
      </div>
      {/* Mobile View */}
      <div className="sm:hidden">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 lg:grid-cols-5 gap-4 md:gap-6 lg:gap-8 block sm:hidden">
          <IconItem image={HubungiAdmin} label="Hubungi Admin" label2="5-10 Menit" />
          <IconItem image={KunjunganDemo} label="Kunjungan Demo" label2="1 Bulan" />
          <IconItem image={TTDkerjasama} label="Tanda Tangan Kerja Sama" label2="1 Bulan" />
          <IconItem image={PengembanganP} label="Pengembangan Program" label2="1 Bulan" />
          <IconItem image={MasaPercobaan} label="Masa Percobaan" label2="1 Bulan" />
          <IconItem image={Done} label="Done" />
        </div>
      </div>
    </div>
  );
};

const IconItem = ({ image, label, label2 }) => (
  <div className="relative">
    <div className="flex items-center justify-center pt-4 mx-auto"> {/* Flex container */}
      <img 
        src={image} 
        alt={label} 
        className="w-full mx-auto" 
      /> 
    </div>
    <div className="text-center mt-2 pt-2">
      <span className="font-inter block">{label}</span>
      {label2 && <span className="font-inter text-s text-gray-500">{label2}</span>}
    </div>
  </div>
);

export default WorkProcess;
