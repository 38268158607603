import React, { useState, useEffect, useRef } from 'react';
import Quote from '../../../assets/svg/quote.svg';
import Engko from '../../../assets/svg/engko.svg';
import { useSwipeable } from 'react-swipeable';

const slides = [
    {
        id: 1,
        quote: "Sejauh ini kita merasa sangat terbantu dengan hadirnya elKopra dalam membantu aktivitas koperasi kami. Kami harap adanya penambahan dan pengembangan fitur lain yang dapat meningkatkan produktivitas dan efisiensi koperasi karyawan Toyota.",
        company: "Koperasi Karyawan PT.TMMIN"
    },
    {
        id: 2,
        quote: "Lorem ipsum dolor sit amet consectetur. Dui aliquam facilisis sed tortor enim orci. Gravida libero tellus bibendum volutpat mi senectus ornare pharetra. Consectetur ultricies hendrerit id quis nunc dolor.",
        company: "John Doe - Rektor ABC"
    },
    {
        id: 3,
        quote: "Lorem ipsum dolor sit amet consectetur. Dui aliquam facilisis sed tortor enim orci. Gravida libero tellus bibendum volutpat mi senectus ornare pharetra. Consectetur ultricies hendrerit id quis nunc dolor.",
        company: "Rektor UPN"
    }
];

const Testimoni = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const slideWidth = 100 / slides.length;

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
    };

    // Use a ref to keep track of the interval
    const intervalRef = useRef(null);

    const resetInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
            nextSlide();
        }, 5000); // Change slide every 5 seconds
    };

    useEffect(() => {
        resetInterval();

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            nextSlide();
            resetInterval(); // Reset interval on swipe
        },
        onSwipedRight: () => {
            prevSlide();
            resetInterval(); // Reset interval on swipe
        },
        swipeDuration: 500,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const handleButtonClick = (index) => {
        setCurrentSlide(index);
        resetInterval(); // Reset interval when button is clicked
    };

    return (
        <div className="text-center">
            <h1 className="font-inter text-sm mt-10 text-blue-950">Testimoni</h1>
            <div className="text-center">
                <h1 className="font-montserrat text-2xl">Cerita dari Mereka</h1>
                <div className="relative mt-10">
                    <div className="overflow-hidden max-w-screen-xl mx-auto" {...handlers}>
                        <div
                            className="flex transition-transform duration-500 ease-in-out"
                            style={{ transform: `translateX(-${currentSlide * slideWidth}%)`, width: `${slides.length * 100}%` }}
                        >
                            {slides.map((slide) => (
                                <div
                                key={slide.id}
                                className="w-full sm:w-6/12 md:w-4/12 lg:w-1/4 px-4"
                            >
                                <div
                                    className={`${
                                        slide.id % 2 === 0 ? 'bg-blue-500 text-white' : 'bg-white'
                                    } rounded-lg shadow-lg p-6 mx-auto max-w-screen-sm`}
                                >
                                    <img src={Quote} alt="Quote" className="mx-auto" />
                                    <div className="w-full pt-6 text-center">
                                        <span className="font-inter tracking-wide text-sm">
                                            {slide.quote}
                                        </span>
                                    </div>
                                    <img src={Engko} alt="Engko" className="mt-4 mx-auto" />
                                    <div className="w-full pt-2 text-center">
                                      <span
                                        className={`${
                                          slide.id % 2 === 0
                                            ? 'font-inter text-sm text-white'
                                            : 'font-inter text-sm text-gray-500'
                                        }`}
                                      >
                                        {slide.company}
                                      </span>
                                    </div>
                                </div>
                            </div>
                            ))}
                        </div>
                        <div className="flex justify-center mt-4">
                            {slides.map((_, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleButtonClick(index)} // Call the reset function when clicked
                                    className={`w-20 h-2 mx-2 rounded-full border-none ${currentSlide === index ? 'bg-blue-500' : 'bg-gray-300'}`}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimoni;
