import React from 'react';
import koperasiSimpanPinjam from '../../../assets/svg/pricing/koperasisimpanpinjam.svg';
import koperasiKonsumen from '../../../assets/svg/pricing/koperasikonsumen.svg';
import koperasiProdusen from '../../../assets/svg/pricing/koperasiprodusen.svg';
import KoperasiJasa from '../../../assets/svg/pricing/koperasijasa.svg';
import koperasiPemasaran from '../../../assets/svg/pricing/koperasipemasaran.svg';
import checklist from '../../../assets/svg/pricing/checklist.svg';
import close from '../../../assets/svg/pricing/close.svg';
import { Link } from 'react-router-dom';

const FeaturePricing = () => {
  return (
    <>
      <div className="container mx-auto flex items-center mt-10">
        <div className="bg-blue-900 p-1 h-10"></div>
        <p className="text-left font-montserrat text-2xl font-semibold px-4">
          Temukan berbagai pilihan untuk memenuhi kebutuhan Koperasi Anda!
        </p>
      </div>
      <div className="container mx-auto mb-5 mt-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-4">
          {/* Feature Column */}
          <div className="p-4 bg-white rounded-lg flex flex-col justify-between">
            <div className="text-center justify-center"></div>
            <div class="mt-20"></div>
            <h1 className="font-montserrat text-3xl text-gray-900 text-center mt-10">
              Feature
            </h1>
              <h1 className="font-inter text-sm text-gray-900 font-normal text-center mt-4">
                POS Application
              </h1>
              <h1 className="font-inter text-sm text-gray-900 font-normal text-center mt-4">
                s/d 100 Registered <br /> members
              </h1>
              <h1 className="font-inter text-sm text-gray-900 font-normal text-center mt-4">
                3rd Payment
              </h1>
              <h1 className="font-inter text-sm text-gray-900 font-normal text-center mt-4">
                QRIS
              </h1>
              <h1 className="font-inter text-sm text-gray-900 font-normal text-center mt-4">
                Reporting System
              </h1>
              <h1 className="font-inter text-sm text-gray-900 font-normal text-center mt-4">
                Requested Fitur
              </h1>
              <div class="mt-20"></div>
          </div>

          {/* Koperasi Simpan Pinjam */}
          <div className="p-4 bg-white rounded-xl flex flex-col justify-between">
            <div className="text-center">
              <img src={koperasiSimpanPinjam} alt="" className="mx-auto" />
            </div>
            <h1 className="font-montserrat text-base text-[#004AAD] text-center mt-10">
              Koperasi <br /> Simpan Pinjam
            </h1>
            <div>
              <div className="text-center mt-10">
                <img src={checklist} alt="" className="mx-auto" />
                <h1 className="font-inter text-sm text-gray-900 font-normal mt-2">
                  s/d 100 Registered <br /> members
                </h1>
              </div>
              <div className="text-center mt-10">
                <img src={checklist} alt="" className="mx-auto" />
              </div>
              <div className="text-center mt-10">
                <img src={close} alt="" className="mx-auto" />
              </div>
              <div className="text-center mt-10">
                <img src={checklist} alt="" className="mx-auto" />
              </div>
              <div className="text-center mt-10">
                <img src={close} alt="" className="mx-auto" />
              </div>
            </div>
            <div className="text-center">
              <Link to={'/featurepricing'}>
                <button className="bg-blue-900 text-white font-base py-3 px-4 rounded-md mb-5 font-inter border-none mt-10 cursor-pointer">
                  UJI COBA GRATIS
                </button>
              </Link>
            </div>
          </div>

          {/* Koperasi Konsumen */}
          <div className="p-4 bg-white rounded-xl flex flex-col justify-between">
            <div className="text-center">
              <img src={koperasiKonsumen} alt="" className="mx-auto" />
            </div>
            <h1 className="font-montserrat text-base text-[#004AAD] text-center mt-10">
              Koperasi <br /> Konsumen
            </h1>
            <div>
              <div className="text-center mt-10">
                <img src={checklist} alt="" className="mx-auto" />
                <h1 className="font-inter text-sm text-gray-900 font-normal mt-2">
                  s/d 100 Registered <br /> members
                </h1>
              </div>
              <div className="text-center mt-10">
                <img src={checklist} alt="" className="mx-auto" />
              </div>
              <div className="text-center mt-10">
                <img src={close} alt="" className="mx-auto" />
              </div>
              <div className="text-center mt-10">
                <img src={checklist} alt="" className="mx-auto" />
              </div>
              <div className="text-center mt-10">
                <img src={close} alt="" className="mx-auto" />
              </div>
            </div>
            <div className="text-center">
              <Link to={'/featurepricing'}>
                <button className="bg-blue-900 text-white font-base py-3 px-4 rounded-md mb-5 font-inter border-none mt-10 cursor-pointer">
                  UJI COBA GRATIS
                </button>
              </Link>
            </div>
          </div>

          {/* Koperasi Produsen */}
          <div className="p-4 bg-white rounded-xl flex flex-col justify-between">
            <div className="text-center">
              <img src={koperasiProdusen} alt="" className="mx-auto" />
            </div>
            <h1 className="font-montserrat text-base text-[#004AAD] text-center mt-10">
              Koperasi <br /> Produsen
            </h1>
            <div>
              <div className="text-center mt-10">
                <img src={checklist} alt="" className="mx-auto" />
                <h1 className="font-inter text-sm text-gray-900 font-normal mt-2">
                  s/d 100 Registered <br /> members
                </h1>
              </div>
              <div className="text-center mt-10">
                <img src={checklist} alt="" className="mx-auto" />
              </div>
              <div className="text-center mt-10">
                <img src={close} alt="" className="mx-auto" />
              </div>
              <div className="text-center mt-10">
                <img src={checklist} alt="" className="mx-auto" />
              </div>
              <div className="text-center mt-10">
                <img src={close} alt="" className="mx-auto" />
              </div>
            </div>
            <div className="text-center">
              <Link to={'/featurepricing'}>
                <button className="bg-blue-900 text-white font-base py-3 px-4 rounded-md mb-5 font-inter border-none mt-10 cursor-pointer">
                  UJI COBA GRATIS
                </button>
              </Link>
            </div>
          </div>

          {/* Koperasi Jasa */}
          <div className="p-4 bg-white rounded-xl flex flex-col justify-between">
            <div className="text-center">
              <img src={KoperasiJasa} alt="" className="mx-auto" />
            </div>
            <h1 className="font-montserrat text-base text-[#004AAD] text-center mt-10">
              Koperasi <br /> Jasa
            </h1>
            <div>
              <div className="text-center mt-10">
                <img src={checklist} alt="" className="mx-auto" />
                <h1 className="font-inter text-sm text-gray-900 font-normal mt-2">
                  s/d 100 Registered <br /> members
                </h1>
              </div>
              <div className="text-center mt-10">
                <img src={checklist} alt="" className="mx-auto" />
              </div>
              <div className="text-center mt-10">
                <img src={close} alt="" className="mx-auto" />
              </div>
              <div className="text-center mt-10">
                <img src={checklist} alt="" className="mx-auto" />
              </div>
              <div className="text-center mt-10">
                <img src={close} alt="" className="mx-auto" />
              </div>
            </div>
            <div className="text-center">
              <Link to={'/featurepricing'}>
                <button className="bg-blue-900 text-white font-base py-3 px-4 rounded-md mb-5 font-inter border-none mt-10 cursor-pointer">
                  UJI COBA GRATIS
                </button>
              </Link>
            </div>
          </div>

          {/* Koperasi Pemasaran */}
          <div className="p-4 bg-white rounded-xl flex flex-col justify-between">
            <div className="text-center">
              <img src={koperasiPemasaran} alt="" className="mx-auto" />
            </div>
            <h1 className="font-montserrat text-base text-[#004AAD] text-center mt-10">
              Koperasi <br /> Pemasaran
            </h1>
            <div>
              <div className="text-center mt-10">
                <img src={checklist} alt="" className="mx-auto" />
                <h1 className="font-inter text-sm text-gray-900 font-normal mt-2">
                  s/d 100 Registered <br /> members
                </h1>
              </div>
              <div className="text-center mt-10">
                <img src={checklist} alt="" className="mx-auto" />
              </div>
              <div className="text-center mt-10">
                <img src={close} alt="" className="mx-auto" />
              </div>
              <div className="text-center mt-10">
                <img src={checklist} alt="" className="mx-auto" />
              </div>
              <div className="text-center mt-10">
                <img src={close} alt="" className="mx-auto" />
              </div>
            </div>
            <div className="text-center">
              <Link to={'/featurepricing'}>
                <button className="bg-blue-900 text-white font-base py-3 px-4 rounded-md mb-5 font-inter border-none mt-10 cursor-pointer">
                  UJI COBA GRATIS
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturePricing;
