import React, { useEffect, useState } from 'react';
import logoheader from '../../../assets/svg/logo.svg';
import whatsapp from '../../../assets/svg/whatsapp2.svg';
import next from '../../../assets/svg/nextbutton.svg';
import back from '../../../assets/svg/backbutton.svg';
import imagecard2 from '../../../assets/svg/widgetclosed.svg';
import Lottie from 'react-lottie';
import Deetail1 from '../../../assets/json/deets2.json';
import Deetail2 from '../../../assets/json/transaksi.json';
import Deetail3 from '../../../assets/json/ppob.json';
import { Navbar } from '../../../global';
import { Link } from 'react-router-dom';

const HeaderHome = () => {
  const slides = [
    {
      id: 1,
      titleLarge: 'Membangun Ekosistem Koperasi Masa Depan Sebagai Pilar Ekonomi Indonesia',
      paragraph: 'Kami berinovasi memanfaatkan teknologi demi meningkatkan daya saing koperasi dengan menjaga sistem tata kelola manajemen yang efektif dan solutif.',
      animationData: Deetail1,
    },
    {
      id: 2,
      titleLarge: 'Kelola Pelaporan dan Keanggotan Koperasi dengan Mudah.',
      paragraph: 'Bersama elKopra kelola pelaporan transaksi dengan lebih mudah! #KoperasiMasaDepan.',
      animationData: Deetail2,
    },
    {
      id: 3,
      titleLarge: 'Mengembangkan Sub Unit Koperasi secara Digital.',
      paragraph: 'Bersama elKopra meningkatkan dan mengembangkan transaksional koperasi! #KoperasiMasaDepan.',
      animationData: Deetail3,
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000); 

    return () => clearInterval(interval); 
  }, [currentSlide]); 

  const whatsappNumber = '6282218591556';
  return (
    <div className='header-home-wraper'>
      {/* <Navbar/> */}
      <div className='header-title-wraper'>
        <div className="rounded-lg flex flex-col lg:flex-row p-20">
          <button className="prev-slide hidden" onClick={prevSlide}>
            <img src={back}  />
          </button>
          <div className='lg:w-1/2 p-5'>
            <h1 className='header-title-small text-left'>Aplikasi Digitalisasi Koperasi</h1>
            <h1 className='header-title-large text-left'>{slides[currentSlide].titleLarge}</h1>
            <p className='header-paragraph text-left'>{slides[currentSlide].paragraph}</p>
            <div className='mt-6 lg:mt-12'>
            <Link 
              to={`https://wa.me/${whatsappNumber}`} 
              target="_blank" 
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <button className="button-header-whatsapp flex items-center">
                <img src={whatsapp} alt='WhatsApp Icon' className='image-header-icon mr-2' />  
                HUBUNGI KAMI
              </button>
            </Link>
            </div>
          </div>
          <div className='pt-20 lg:w-1/2 hidden lg:block'> {/* Hide on small screens, show on large screens */}
            <Lottie options={{
              loop: true,
              autoplay: true,
              animationData: slides[currentSlide].animationData,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid meet'
              },
            }} height={350} width={550} speed={1} alt='' />
          </div>
          <button className="next-slide hidden" onClick={nextSlide}>
            <img src={next} />
          </button>
        </div>
      </div>
      <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g className="parallax">
          <use xlinkHref="#gentle-wave" x="48" y="0" fill="#5689CE" fillOpacity="0.60" />
          <use xlinkHref="#gentle-wave" x="48" y="3" fill="#5689CE" fillOpacity="0.60" />
          <use xlinkHref="#gentle-wave" x="48" y="5" fill="#5689CE" fillOpacity="0.60" />
          <use xlinkHref="#gentle-wave" x="48" y="20" fill="#5689CE" fillOpacity="0.60" />
          <use xlinkHref="#gentle-wave" x="48" y="20" fill="#5689CE" fillOpacity="0.60" />
        </g>
      </svg>
      <div className="carousel-controls"></div>
    </div>
  );
}

export default HeaderHome;
