import React, { useState } from 'react';
import home from '../../../../assets/svg/Home.svg';
import { Link } from 'react-router-dom';
import Pagination from '../../../../helper/Pagination';
import { useControllerState} from '../../../../controller/blogpage'
const HeaderBlogPage = () => {
  const { berita, loading } = useControllerState();
  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage] = useState(5); 
  const [selectedCategory, setSelectedCategory] = useState('Semua');

  
  const filteredArticles = berita.filter(article => 
    article.status === "PUBLISHED" && 
    (selectedCategory === 'Semua' || article.categories === selectedCategory)
  );

  const totalPages = Math.ceil((filteredArticles?.length || 0) / articlesPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const currentArticles = (filteredArticles || []).slice(
    (currentPage - 1) * articlesPerPage,
    currentPage * articlesPerPage
  );
  return (
    <>
      <div className='p-12'></div>
<div className="container mx-auto px-4 py-6">
  <nav className="text-sm flex items-center space-x-2 mb-6">
    <a href="/" className="text-gray-400 hover:underline font-inter no-underline flex items-center">
      <img src={home} alt="HOME" className="mr-2" />
      Home
    </a>
    <span>/</span>
    <a href="/news" className="text-gray-400 hover:underline font-inter no-underline flex items-center">
      News & Blog
    </a>
    <span>/</span>
    <span className="text-blue-600 font-inter font-semibold">{selectedCategory}</span>
  </nav>
  <div className="flex flex-wrap space-x-4 mb-6 overflow-x-auto">
    <button
      onClick={() => { 
        setSelectedCategory('Semua'); 
        setCurrentPage(1); 
      }}
      className={`${
        selectedCategory === 'Semua' ? 'bg-blue-900 text-white' : 'bg-white text-gray-400'
      } px-4 py-2 font-inter text-sm border-none rounded-3xl cursor-pointer w-28 md:w-auto`}
    >
      Semua
    </button>
    <button
      onClick={() => { 
        setSelectedCategory('BISNIS'); 
        setCurrentPage(1); 
      }}
      className={`${
        selectedCategory === 'BISNIS' ? 'bg-blue-900 text-white' : 'bg-white text-gray-400'
      } px-4 py-2 font-inter text-sm border-none rounded-3xl cursor-pointer w-28 md:w-auto`}
    >
      Bisnis
    </button>
    <button
      onClick={() => { 
        setSelectedCategory('EKONOMI'); 
        setCurrentPage(1); 
      }}
      className={`${
        selectedCategory === 'EKONOMI' ? 'bg-blue-900 text-white' : 'bg-white text-gray-400'
      } px-4 py-2 font-inter text-sm border-none rounded-3xl cursor-pointer w-28 md:w-auto`}
    >
      Ekonomi
    </button>
    <button
      onClick={() => { 
        setSelectedCategory('HUKUM'); 
        setCurrentPage(1); 
      }}
      className={`${
        selectedCategory === 'HUKUM' ? 'bg-blue-900 text-white' : 'bg-white text-gray-400'
      } px-4 py-2 font-inter text-sm border-none rounded-3xl cursor-pointer w-28 md:w-auto`}
    >
      Hukum
    </button>
    <button
      onClick={() => { 
        setSelectedCategory('INFOGRAFIS'); 
        setCurrentPage(1); 
      }}
      className={`${
        selectedCategory === 'INFOGRAFIS' ? 'bg-blue-900 text-white' : 'bg-white text-gray-400'
      } px-4 py-2 font-inter text-sm border-none rounded-3xl cursor-pointer w-28 md:w-auto`}
    >
      Infografis
    </button>
    <button
      onClick={() => { 
        setSelectedCategory('INSPIRASI BISNIS'); 
        setCurrentPage(1); 
      }}
      className={`${
        selectedCategory === 'INSPIRASI BISNIS' ? 'bg-blue-900 text-white' : 'bg-white text-gray-400'
      } px-4 py-2 font-inter text-sm border-none rounded-3xl cursor-pointer w-32 md:w-auto`}
    >
      Inspirasi Bisnis
    </button>
    <button
      onClick={() => { 
        setSelectedCategory('INTERNASIONAL'); 
        setCurrentPage(1); 
      }}
      className={`${
        selectedCategory === 'INTERNASIONAL' ? 'bg-blue-900 text-white' : 'bg-white text-gray-400'
      } px-4 py-2 font-inter text-sm border-none rounded-3xl cursor-pointer w-28 md:w-auto`}
    >
      Internasional
    </button>
  </div>
  <div className="space-y-6">
    {currentArticles.length > 0 ? (
      currentArticles.map((article) => (
        <div
      key={article.id}
      className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 items-start pb-6" 
    >
      <img
        src={article.thumbnail}
        alt={article.judul}
        className="w-full sm:w-32 h-24 object-cover rounded"
      />
      <div>
        <span className="block text-sm text-blue-600 font-semibold mb-1 font-inter">
          {article.categories}  
        </span>
        <Link
          to={`/detailnews/${article.id}`}
          className="text-lg font-semibold text-gray-800 hover:underline font-montserrat no-underline"
        >
          {article.judul}   
        </Link>
      </div>
    </div>
      ))
    ) : (
      <div className="flex flex-col space-y-4">
        {[...Array(3)].map((_, index) => (
          <div key={index} className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 items-start border-b pb-6 animate-pulse">
            <div className="w-full sm:w-32 h-24 bg-gray-300 rounded" />
            <div className="flex-1">
              <span className="block h-4 bg-gray-300 rounded mb-1" />
              <div className="h-6 bg-gray-300 rounded" />
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
  <div className="flex justify-end mt-8 space-x-2">
     <Pagination
      totalPages={totalPages}
      currentPage={currentPage}
      onPageChange={handlePageChange}
    />
  </div>
</div>
    </>
  );
};

export default HeaderBlogPage;
